import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CreatorMarquee from '../Components/CreatorMarquee';
import mobileHero from '../assets/images/mobileview.avif';
import instagramIcon from '../assets/images/instagram_blue.svg';
import './HomePage.css';

const HomePage = () => {
  useEffect(() => {
    // Title effect
    const originalTitle = document.title;
    document.title = 'TLINK.ME - Monetize Your Telegram';
    
    // Intersection Observer for features
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: '50px',
      }
    );

    // Observe all feature sections
    document.querySelectorAll('.feature').forEach((feature) => {
      observer.observe(feature);
    });

    return () => {
      document.title = originalTitle;
      observer.disconnect();
    };
  }, []);

  return (
    <div className="home-container">
      {/* Header */}
      <header className="header">
        <div>
          <div className="logo">
            <Link to="/" className="logo-link">
              <h1>TLINK.ME</h1>
            </Link>
          </div>
        </div>
      </header>

      <div className="content-wrapper">
        {/* Hero Section */}
        <section className="hero-section">
          <img src={mobileHero} alt="TLINK.ME Hero" className="mobile-hero-image" />
          <h1 className="hero-title">
            MONETIZE YOUR<br />
            TELEGRAM.
          </h1>
          <p className="hero-subtitle">
            Monetize your direct messages<br />
            & exclusive content on Telegram.
          </p>
          <Link to="/creators" className="become-creator-btn">
            Become a Creator
          </Link>
        </section>

        {/* Creators Section */}
        <section className="creators-section">
          <h2 className="section-title">OUR CREATORS</h2>
          <CreatorMarquee />
        </section>

        {/* Features Section */}
        <section className="features-section">
          {/* First Feature - Revenue Stream */}
          <div className="feature">
            <div>
              <div className="feature-content">
                <span className="tag revenue">EXCLUSIVE</span>
                <h2>UNLOCK A NEW REVENUE STREAM</h2>
                <p>Turn your Telegram profile into a fan-powered community and monetize your exclusive content and conversations.</p>
              </div>
              <div className="feature-image-wrapper">
                <div className="feature-image phone-mockup"></div>
              </div>
            </div>
          </div>

          {/* Second Feature - VIP Messages */}
          <div className="feature reverse">
            <div>
              <div className="feature-content">
                <span className="tag vip">VIP</span>
                <h2>MONETIZE YOUR DIRECT MESSAGES</h2>
                <p>Give your fans the VIP experience by letting them DM your profile directly.</p>
              </div>
              <div className="feature-image-wrapper">
                <div className="feature-image chat-mockup"></div>
              </div>
            </div>
          </div>

          {/* Third Feature - Analytics */}
          <div className="feature">
            <div>
              <div className="feature-content">
                <span className="tag analytics">ANALYTICS</span>
                <h2>TRACK YOUR STATS & SUBSCRIBERS</h2>
                <p>Track your monthly income, subscribers, conversations, and more.</p>
              </div>
              <div className="feature-image-wrapper">
                <div className="feature-image stats-mockup"></div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Footer CTA */}
      <section className="footer-cta">
        <div className="marquee">
          <div className="marquee-content">
            {Array(100).fill('').map((_, index) => (
              <span key={index}>
                <span className="join">JOIN</span>
                <span className="domain">TLINK.ME</span>
                <span className="now">NOW</span>
              </span>
            ))}
          </div>
        </div>
        <div className="cta-box">
          <div>
            <h2>
              <span className="cta-static">HOME FOR</span>
              <div className="cta-slider-container">
                <div className="cta-slider">
                  <div className="cta-slide">BLOGGERS</div>
                  <div className="cta-slide">STREAMERS</div>
                  <div className="cta-slide">ACTORS</div>
                  <div className="cta-slide">ENTREPRENEURS</div>
                  <div className="cta-slide">ARTISTS</div>
                  <div className="cta-slide">YOU</div>
                </div>
              </div>
            </h2>
            <p>Join our community of creators and tap into<br />an audience of millions of people.</p>
            <Link to="/creators" className="become-creator-footer">
              BECOME A CREATOR
            </Link>
          </div>
        </div>
        <footer className="footer">
          <div className="footer-content">
            <div className="footer-left">
              <span className="footer-title">FOLLOW US</span>
              <div className="footer-social">
                <img src={instagramIcon} alt="Instagram" className="footer-instagram-icon" />
                <a href="https://instagram.com/tlink.me" target="_blank" rel="noopener noreferrer" className="footer-handle">TLINK.ME</a>
              </div>
            </div>
            <div className="footer-center">
              <div className="footer-logo">TLINK.ME</div>
            </div>
            <div className="footer-right">
              <div className="footer-copyright">
                ALL RIGHTS RESERVED.
                <span className="footer-year">2025</span>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </div>
  );
};

export default HomePage; 