import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './CreatorMarquee.css';

// Import creator images
import creator1 from '../assets/creators/creator1.avif';
import creator2 from '../assets/creators/creator2.avif';
import creator3 from '../assets/creators/creator3.avif';
import creator4 from '../assets/creators/creator4.avif';
import creator5 from '../assets/creators/creator5.avif';
import creator6 from '../assets/creators/creator6.avif';
import creator7 from '../assets/creators/creator7.avif';
import creator8 from '../assets/creators/creator8.avif';
const CREATORS = [
  { id: 1, name: 'SOMMER RAY', followers: '23.6M', image: creator1, link: 'https://t.me/sommerrayofficial' },
  { id: 2, name: 'AMBER QUINN', followers: '2.2M', image: creator2, link: 'https://t.me/amberquinnn' },
  { id: 3, name: 'LANA RHOADES', followers: '15M', image: creator3, link: 'https://tlink.me' },
  { id: 4, name: 'ABBY RAO', followers: '2.2M', image: creator4, link: 'https://t.me/theabbyrao' },
  { id: 5, name: 'MIKAELA LAFUENTE', followers: '1.3M', image: creator5, link: 'https://t.me/mikaelalafuente_official' },
  { id: 6, name: 'NATASHA ELECTRA', followers: '127K', image: creator6, link: 'https://t.me/natashaelectra' },
  { id: 7, name: 'KRISTINA LEVINA', followers: '701K', image: creator7, link: 'https://t.me/le_xtinaa' },
  { id: 8, name: 'ANTHIA MO', followers: '1.2M', image: creator8, link: 'https://t.me/anthiamo_official' },
];

// Triple the creators array for smooth infinite scroll
const TRIPLED_CREATORS = [...CREATORS, ...CREATORS, ...CREATORS, ...CREATORS];

const CreatorMarquee = () => {
  const scrollRef = useRef(null);
  const marqueeAnimation = useRef(null);

  useEffect(() => {
    const itemWidth = 180 + 24; // width + gap
    const totalWidth = CREATORS.length * itemWidth;
    
    // Set initial position
    gsap.set(scrollRef.current, { x: 0 });

    // Create the marquee animation
    marqueeAnimation.current = gsap.timeline({
      repeat: -1,
      defaults: { ease: 'none' }
    })
    .to(scrollRef.current, {
      x: -totalWidth,
      duration: CREATORS.length * 5,
      ease: 'none',
    })
    .to(scrollRef.current, {
      x: 0,
      duration: 0,
    });

    // Cleanup
    return () => {
      if (marqueeAnimation.current) {
        marqueeAnimation.current.kill();
      }
    };
  }, []);

  const handleCreatorClick = (link) => {
    window.open(link, '_blank');
  };

  return (
    <div className="marquee-container">
      <div className="marquee-inner" ref={scrollRef}>
        {TRIPLED_CREATORS.map((creator, index) => (
          <div
            key={`${creator.id}-${index}`}
            className="marquee-item"
            onClick={() => handleCreatorClick(creator.link)}
          >
            <div 
              className="creator-image" 
              style={{ backgroundImage: `url(${creator.image})` }}
            >
              <div className="creator-info">
                <span className="creator-name">{creator.name}</span>
                <div className="creator-stats">
                  <img src="/instagram-white.svg" alt="Instagram" className="creator-instagram" />
                  <span className="creator-followers">{creator.followers}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreatorMarquee; 